const media = {
    'images': ['eric-2.jpg', 'eric-3.jpg', 'eric-4.jpg', 'eric-5.jpg', 'eric-6.jpeg', 'eric-7.jpeg'],
    'videos': [
        'https://www.youtube.com/embed/4WkVYz2K89Y',
        'https://www.youtube.com/embed/EOakIIv5sGc',
        'https://www.youtube.com/embed/gh1J8q112b4',
        'https://www.youtube.com/embed/uOdzIn1BVM4',
        'https://www.youtube.com/embed/tW5GttGhR8I'
    ]
};

function drawMedia(type = null) {
    let media_container = document.querySelector('.media-container');

    // clear the container
    let videos = media_container.querySelectorAll('iframe');
    let imgs = media_container.querySelectorAll('img');

    videos.forEach(video => video.remove());
    imgs.forEach(img => img.remove());


    let img_counter = 0;

    if (type == 'video' || type == null || type == undefined) {
        for (let v = 0; v < media.videos.length; v++) {
            let frame = document.createElement('iframe');
            frame.classList.add('video');
            frame.setAttribute('src', media.videos[v]);
            frame.setAttribute('title', 'YouTube video player');
            frame.setAttribute('frameborder', '0');
            frame.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
            frame.allowFullscreen = true;

            media_container.append(frame);

            if (type == null || type == undefined) {
                let render_imgs = img_counter+4 <= media.images.length;
                if (render_imgs || img_counter < media.images.length) {
                    let max = (render_imgs) ? img_counter + 4 : media.images.length;
                    for (let i=img_counter; i < max; i++) {
                        let img = document.createElement('img');
                        img.src = `/static/media/${media.images[i]}`;
                        media_container.append(img);
                    }
                }

                img_counter += 4;
            }
            
        }
    } else if (type == 'image') {
        for (let i=0; i < media.images.length; i++) {
            let img = document.createElement('img');
            img.src = `/static/media/${media.images[i]}`;
            media_container.append(img);
        }
    }
}

document.addEventListener('DOMContentLoaded', e => {
    drawMedia();
    let filters = document.querySelectorAll('.filters .filter');
    filters.forEach(filter => {
        filter.addEventListener('click', e => {
            document.querySelector('.filter.active').classList.remove('active');
            filter.classList.add('active');
            let type = filter.getAttribute('type');
            drawMedia(type);
        }); 
    })
});